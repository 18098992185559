<template>
    <button style="visibility:hidden" type="button" id="logoutbutton" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
      Pre Logout Notice
    </button>
    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel"><i class="icon fa fa-warning text-yellow"></i> Your session is about to expire.</h5>
          </div>
          <div class="modal-body">
           <p> You will be logged out in 0 seconds.</p>
           <button class="btn btn-primary" type="button">
                        Continue Session
            </button>

            <button class="btn btn-danger btn-delete mleft-5" type="button">
                Logout
            </button>
        </div>
        
        </div>
      </div>
    </div>
    
    </template>
    <script type="text/javascript">
    export default {
      name: 'logouty notice',
       components: {
        
      },
      data() {
      },
      methods: {
        showModal() {
            document.getElementById("logoutbutton").click(); 
        },
      },
      mounted() { 
        this.showModal();
      }
    
    }
    
    </script>